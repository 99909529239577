<template>
  <div>
    <!-- <bread-crumb>
      <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{
          name: 'choose-table',
          params: {
            productID: $route.query.productID,
            bankId: $route.query.bankID,
            productName: $route.query.productName,
          },
        }"
      >{{ tableName }}
      </el-breadcrumb-item
      >
      <el-breadcrumb-item :to="$route.query.redirectTo">{{
          zqName
        }}
      </el-breadcrumb-item>
      <el-breadcrumb-item>{{ tableName }}</el-breadcrumb-item>
      <el-breadcrumb-item>手工填报</el-breadcrumb-item>
    </bread-crumb> -->
    <div class="content-body">
      <div class="wrapper">
        <div class="table-wrapper">
          <div class="toolbar">
            <div class="backTo" @click="btn_back">返回上页</div>
<!--            <button class="btn btn_back btnDownload" @click="btnDownload">下载表格</button>-->
            <span></span>
          </div>

          <h1 class="title">{{ tableName }}</h1>


          <div
            class="tableSwitcher"
            v-if="isShowKjzdSelector || isShowCarryoutVersionSelector"
          >
            会计制度：
            <el-select
              v-if="isShowKjzdSelector"
              v-model="kjzd"
              filterable
              placeholder="请选择"
              class="select"
              @change="handleKjzdChange"
            >
              <el-option
                v-for="item in kjzdOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <div class="table-selector" v-if="isShowCarryoutVersionSelector">
              <el-radio v-model="carryOutVersion" @change="handleKjzdChange" label=""
              >已执行利润表
              </el-radio>
              <el-radio v-model="carryOutVersion" @change="handleKjzdChange" label="_OLD"
              >未执行利润表
              </el-radio>
            </div>
          </div>
          <div class="company-list">
            <div class="company-list-item">
              <div>企业名称：{{ $route.query.companyName }}</div>
              <div>上传时间：{{ createTime }}</div>
            </div>
            <div class="company-list-item">
              <div>纳税人识别号：{{ $route.query.taxNo }}</div>
              <div>所属时期：{{ timeSection }}</div>
            </div>
          </div>
          <div>
            <HandsonTable
              :key="handsonTableIndex"
              :tableId="`${templateID}_${kjzd}`"
              v-if="type == 1"
              v-loading="loading"
              ref="theTable"
              :table="table"
              :zq="$route.query.zq"
              :enableAutoCorrect="enableAutoCorrect"
              @getInstance="getInstance"
            ></HandsonTable>
            <HtmlTable
              v-if="type == 2"
              :key="handsonTableIndex"
              tableId="`${templateID}_${kjzd}`"
              class="htmlTable"
              v-loading="loading"
              :data="table"
              :zq="$route.query.zq"
            >
            </HtmlTable>
          </div>
        </div>
        <div class="footer">
          <button class="btn btn_back btn-confirm" @click="btn_back">返回</button>
          <button
            class="btn btn-primary btn-confirm"
            @click="handleBtnSaveClick"
            v-if="type == 1 && $route.params.from !== 'readonly'"
          >
            保存
          </button>

        </div>
      </div>
    </div>
    <!--引导-->

    <!-- <InstructionDialog></InstructionDialog> -->

    <!--/引导-->
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import {mapState} from "vuex";
import InstructionDialog from "./components/InstructionDialog";
import {zq2name, previousZq} from "@/utils/helper";
import {deleteTable} from "@/api/table";
import {
  uploadTableData,
  loadTableStructure,
  loadTableData,
  uploadWebTableExcel,
  getPreviousTableFill,
} from "@/api/table";
import HandsonTable from "@/components/HandsonTable";
import HtmlTable from "@/components/HtmlTable";
import download from "downloadjs";
import {convertHtmlTableFragmentToHtmlFile} from "@/utils/table";

const array2keys = (arr, mapping) => {
  let ret = {};
  for (const [rowNum, row] of arr.entries()) {
    for (const [colNum, v] of row.entries()) {
      try {
        if (mapping[rowNum][colNum]) {
          ret[mapping[rowNum][colNum]] = v;
        }
      } catch (error) {
        debugger;
      }
    }
  }
  return ret;
};

const keys2array = (keys, mapping, data, colHeaders) => {
  let ret = [...data];

  for (const [rowNum, row] of mapping.entries()) {
    //ret[rowNum] = {};
    for (const [colNum, v] of row.entries()) {
      if (keys[v]) {
        const name = colHeaders[colNum];
        ret[rowNum][name] = keys[v];
        //data[rowNum][name] = keys[v];
      }
    }
  }
  return ret;
};

export default {
  components: {
    HandsonTable,
    BreadCrumb,
    InstructionDialog,
    HtmlTable,
  },

  async mounted() {
    await this.loadUserFilledData();
    //选择会计制度
    if (this.uploadedTableData && this.uploadedTableData.cwSign) {
      this.kjzd = this.uploadedTableData.cwSign;
    } else {
      this.kjzd = this.kjzdOptions[0].value;
    }
    this.previouKjzd = this.kjzd;
    this.previouCarryOutVersion = this.carryOutVersion;
    //选择会计制度
    if (this.uploadedTableData && this.uploadedTableData.executeSign === 0) {
      this.carryOutVersion = "_OLD";
    } else {
      this.carryOutVersion = "";
    }

    await this.loadTableData();
  },
  props: {
    // templateID: [String, Number],
    // templateID: [String],
    // productID: [String,Number],
    // bankId: [String],
    // $route.query.productName: [String],
    // zq: [String, Number],
    from: [String],
    // uuid: String,
  },
  computed: {
    ...mapState(["companyInfo"]),

    tableName() {
      return this.$route.query.tableName;
    },
    zqName: function () {
      return zq2name(this.$route.query.zq);
    },
    //是否显示会计制度选择器
    isShowKjzdSelector() {
      return this.templateIdNeedChooseKjzd.includes(this.templateID);
    },
    //是否显示显示已执行 未执行的表选择器
    isShowCarryoutVersionSelector() {
      //带会计制度但不带已执行未执行信息的表ID
      let templateIdWithKJZD = this.templateID;
      if (this.isShowKjzdSelector) {
        templateIdWithKJZD = templateIdWithKJZD + "_" + this.kjzd;
      }

      const ret = this.templateIdNeedChooseCarryoutVersion.includes(templateIdWithKJZD);
      return ret;
    },
    //加上会计制度后的templateId
    realTemplateId() {
      let ret = this.templateID;

      //拼出带有会计制度的表ID
      if (this.isShowKjzdSelector) {
        ret = this.templateID + "_" + this.kjzd;
      }

      //拼出带有已执行 未执行的表ID

      if (this.isShowCarryoutVersionSelector) {
        ret = ret + this.carryOutVersion;
      }

      //增值税及附加税费申报表 （小规模纳税人适用）
      if((this.templateID=='SB_ZZS_YBNSR'&&this.uploadedTableData.cwSign)){
        ret = `SB_ZZS_YBNSR_${this.uploadedTableData.cwSign}`
      }

      return ret;
    },
  },
  methods: {
    storeHtmlTable(){
      let html = "";
      const handsontableDom = document.querySelector(".htCore")
      const wtHolderDom = document.querySelector(".wtHolder")
      const htmlDom = document.querySelector(".htmlTable")

      if(handsontableDom){
        wtHolderDom.scrollTop = 0;
        wtHolderDom.scrollTop = wtHolderDom.scrollHeight;

        setTimeout(()=>{

          html = handsontableDom.outerHTML;
          html = convertHtmlTableFragmentToHtmlFile(html,this.tableName)
          this.tableHtml = html
        },1000)

      }else{
        html = htmlDom.innerHTML;
      }

      html = convertHtmlTableFragmentToHtmlFile(html,this.tableName)
      this.tableHtml = html
    },
    btnDownload() {



      download(new Blob([this.tableHtml], {type: 'text/plain'}), `${this.$route.query.tableName}.html`, "text/html");
    },
    //获取之前上传到服务器excel数据
    async loadUserFilledData() {
      //获取一下表格数据
      let resTableData = await loadTableData(
        this.$route.query.zq,
        this.templateID,
        this.$store.state.authorization,
        this.uuid
      );
      if (resTableData.data) {
        this.uploadedTableData = resTableData.data;
        this.tableName = resTableData.data.excelTableNameCn;
        this.timeSection = resTableData.data.timeSection;
        this.createTime = resTableData.data.createTime;
      } else {
        this.uploadedTableData = null;
      }
    },
    //获取table的所有数据
    async loadTableData() {
      //企业信息
      // await this.$store.cache.dispatch("loadCompanyInfo");
      // var zq = this.$route.query.zq.substr(0, 4);
      const ret = await loadTableStructure({
        templateId: this.realTemplateId,
        zq: this.$route.query.zq,
      });
      //更新一下handsontable组件的key值 让其刷新一下
      this.handsonTableIndex++;
      if (ret.code == 200 && ret.data) {
        this.excelName = ret.data.excelName;

        this.type = parseInt(ret.data.type);

        if (this.type == 1) {
          this.table = JSON.parse(ret.data.excelData);
          this.mapping = JSON.parse(ret.data.excelMap);

          //this.tableName = excelName
          //转化为table数据
          if (this.uploadedTableData) {
            this.convertTableDataToTable(this.uploadedTableData.dataJson);
          } else {
            this.convertTableDataToTable({});
          }
        } else if (this.type == 2) {
          this.table["html"] = ret.data.excelData;
          if (this.uploadedTableData) {
            this.table["data"] = this.uploadedTableData.dataJson;
          } else {
            this.table["data"] = {
              a: 13213,
            };
          }
        }

        // debugger
        if (this.from == "manual") {
          this.listRouterName = "upload-table-manual";
        } else {
          this.listRouterName = "upload-table";
        }
      }

      //如果是上期页面，判断是否要显示自动填充本期上期
      if (this.zq) {
        // await this.getPreFilledData();
      }
      this.storeHtmlTable()
      this.loading = false;
    },
    async handleKjzdChange(value) {
      const _this = this;
      //await this.loadUserFilledData();
      //await this.loadTableData();
      //this.handsonTableIndex++;
      if (this.uploadedTableData) {
        this.$confirm(`切换会计制度后当前填写内容将被清空, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(
          async () => {
            this.previouKjzd = this.kjzd;
            this.previouCarryOutVersion = this.carryOutVersion;
            this.loading = true;
            const result = await deleteTable(
              _this.companyInfo.nsrsbm,
              this.$route.query.zq,
              this.templateID,
              this.$store.state.authorization,
              this.uuid
            );

            //debugger
            if (result.code == 200) {
              await this.loadUserFilledData();
              await this.loadTableData();
            } else {
              _this.$message.error(result.message);
            }
          },
          () => {
            this.kjzd = this.previouKjzd;
            this.carryOutVersion = this.previouCarryOutVersion;
          }
        );
      } else {
        this.previouKjzd = this.kjzd;
        this.previouCarryOutVersion = this.carryOutVersion;
        await this.loadTableData();
      }
    },
    btn_back() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: this.$route.query.redirectTo,
      // });
    },
    getInstance(instance) {
      // console.log("bbbb");
      this.instance = instance;

      //首次进入验证一下
      const dataTable = this.$refs.theTable.dataTable;
      //  dataTable.invalidCells = [];

      //等到加载差不多的时候再验证单元格
      this.timer = setTimeout(() => {
        this.instance.validateCells();
        this.instance.render();
      }, 1000);
    },
    async handleBtnSaveClick() {
      const dataTable = this.$refs.theTable.dataTable;
      const invalidCells = dataTable.invalidCells;
      dataTable.invalidCells = [];
      if (this.instance) {
        //系统检测到您有部分数据未修改，若不修改系统将默认取0值
        this.instance.validateCells(async (valid) => {
          this.filledResult = this.instance.getData();

          //公式校验正确
          if (this.instance.CustomErrorCells.length === 0) {
            //不正确的数字格式
            if (!valid) {
              let isChangeTo0 = false;
              try {
                isChangeTo0 = await this.$confirm(
                  "系统检测到您有部分数据未修改，若不修改系统将默认取0值",
                  "提示",
                  {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                  }
                );
              } catch (error) {
                return;
              }

              //把错误的单元格改成0
              if (isChangeTo0) {
                invalidCells.forEach((v) => {
                  this.filledResult[v[0]][v[1]] = "0";
                });
              }
            }

            //提交数据
            const data = array2keys(this.filledResult, this.mapping);

            const html = this.instance.toHTML();
            data.source = this.source;
            const resDataAll = await Promise.all([
              uploadTableData(
                this.companyInfo.nsrsbm,
                this.$route.query.zq,
                this.templateID,
                this.$route.params.uuid || this.$route.query.uuid,
                data
              ),
              uploadWebTableExcel({
                tableLabel: html,
                nsrsbh: this.companyInfo.nsrsbm,
                templeteId: this.templateID,
                zq: this.$route.query.zq,
                uuid: this.uuid,
              }),
            ]);

            if (resDataAll[0].code !== 200) {
              throw resDataAll[0];
            } else if (resDataAll[1].msgCode != 1) {
              throw resDataAll[1];
            } else {
              this.storeHtmlTable()
              this.$alert("表格保存成功", "提示", {
                type: "success",
              }).then(() => {
                // this.$router.push({
                //   path: this.$route.query.redirectTo,
                // });
              });
            }
          } else {
            this.instance.render();
            this.$alert("表格填写错误，请检查", "错误", {
              type: "error",
            });
          }
        });
      }
    },
    //返回本期的上期 对应上期的本期
    async getPreFilledData() {
      let preData = await getPreviousTableFill(
        this.companyInfo.nsrsbm,
        this.zq,
        this.templateID
      );

      if (preData.msgCode != "1") {
        return;
      }

      try {
        const zqName = zq2name(this.zq);
        await this.$confirm(
          `系统检测您在${zqName}的${this.$route.query.tableName}表输入了上期数据，是否自动填入到本期内？`,
          "确认信息",
          {
            distinguishCancelAndClose: true,
            confirmButtonText: "自动填入",
            cancelButtonText: "放弃",
          }
        );

        //this.loading = true;
        await this.convertTableDataToTable(this.uploadedTableData.dataJson, preData.data);

        setTimeout(() => {
          this.loading = false;
          this.instance.triggerAutoCorrect();
        }, 500);

        //debugger;
      } catch (error) {
        debugger;
      }
    },

    async convertTableDataToTable(data, preData) {
      if (!data) {
        data = {
          // nsrsbh: "1582260040997",
          // zqId: "2018004",
          // hyId: "3011",
          // dq: "110100"
        };
      }

      //如果没有数据则启用enableAutoCorrect
      if (Object.keys(data).length == 0) {
        this.enableAutoCorrect = true;
      } else {
        this.enableAutoCorrect = false;
      }

      if (preData) {
        data = Object.assign(data, preData);
      }

      if (data) {
        const theData = keys2array(
          data,
          this.mapping,
          this.table.data,
          this.table.colHeaders
        );

        // this.source = data.source;
        // //如果来源是手工填报 则启用自动修正
        //
        // if (data.source === "upload-web") {
        //   this.enableAutoCorrect = true;
        // } else {
        //   this.enableAutoCorrect = false;0
        // }

        this.$set(this.table, "data", theData);
      }

      return data;

      //this.updateTableSettings();
    },
  },
  data() {
    return {
      tableHtml:"",
      templateID: this.$route.query.templateID,
      zq: this.$route.query.zq,
      timeSection: "",
      createTime: "",
      // zq: this.$route.query.zq,
      uuid: this.$route.query.uuid,
      type: 2, // 是1:hanstable还是 2:htmltable
      previouKjzd: "", //上一个会计制度
      previouCarryOutVersion: "", //上一个已执行 未执行的表
      handsonTableIndex: 0,
      loading: false,
      enableAutoCorrect: true,
      listRouterName: "",
      instance: null,
      excelName: "",
      filledResult: {},
      table: {},
      mapping: [],
      source: "upload-web",
      uploadedTableData: null, //之前上传到服务器的表格数据
      timer: null, //定时器
      kjzdOptions: [
        {
          value: "QYKJZD",
          label: "企业会计制度",
        },
        {
          value: "QYKJZZ",
          label: "企业会计准则",
        },
        {
          value: "XQYKJZZ",
          label: "小企业会计准则",
        },
      ],
      carryOutVersion: "_OLD", //已执行利润表 未执行利润表
      kjzd: "", //会计制度
      //需要显示会计制度的表
      templateIdNeedChooseKjzd: ["CW_ZCFZB", "CW_LRB"],
      //需要显示已执行 未执行的表
      templateIdNeedChooseCarryoutVersion: ["CW_LRB_QYKJZZ", "CW_ZCFZB_QYKJZZ"],
    };
  },
};
</script>

<style lang="stylus" scoped>

.tableSwitcher {
  background: #F0F5FF;
  border-radius: 4px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .select {
    width: 240px;
  }

  .table-selector {
    margin-left: 20px;
  }
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 20px;
}

.wrapper {
  background: #fff;
  overflow: hidden;

  .table-wrapper {
    position: relative;
    margin: 10px;
    .toolbar{
      display flex
      justify-content space-between
      .backTo {
        font-size: 14px;
        color: #86909C;
        cursor: pointer;
        text-decoration: underline
      }
      .btnDownload{
        padding 5px 20px
        background: #FFFFFF;
        border-radius: 1px;
        border: 1px solid #165DFF;
        color #165DFF;
        display flex
        justify-content space-between
        align-items center
      }
    }

  }

  .title {
    font-size: 22px;
    color: #062340;
    text-align: center;
    margin: 20px auto;
  }
}

.footer {
  text-align: center;
  padding: 30px 0 70px;

  .tip {
    color: #F13A4C;
  }

  .btn-confirm {
    font-size: 18px;
    padding: 5px 7px;
    margin-top: 10px;
    width: 200px;
  }

  .btn_back {
    color: #007AFF;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin-right: 25px;
  }
}

.company-list {
  margin-bottom: 10px;
}

.company-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.4;
  color: #7f9bc9;
}
</style>
